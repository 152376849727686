import {
	GET_AUDIT_LOG,
	GET_AUDIT_LOG_SUCCESS,
	GET_AUDIT_LOG_ERROR,
 
	GET_AUDIT_LOG_BY_COMPANY,
	GET_AUDIT_LOG_BY_COMPANY_SUCCESS,
	GET_AUDIT_LOG_BY_COMPANY_ERROR,

	GET_FILTERATION_DATA_LOG,
	GET_FILTERATION_DATA_LOG_SUCCESS,
	GET_FILTERATION_DATA_LOG_ERROR,


	

} from "../actions";

export const getAuditLogByCompany = (id) => ({
	type: GET_AUDIT_LOG_BY_COMPANY,
	payload: { id },
});

export const getAuditLogByCompanySuccess = (auditlog) => ({
	type: GET_AUDIT_LOG_BY_COMPANY_SUCCESS,
	payload: auditlog,
});

export const getAuditLogByCompanyError = (message) => ({
	type: GET_AUDIT_LOG_BY_COMPANY_ERROR,
	payload: { message },
})


export const getAuditLog = (employees, history) => ({
	type: GET_AUDIT_LOG,
	// payload: { employees, history },
});

export const getAuditLogSuccess = (auditlog) => ({
	type: GET_AUDIT_LOG_SUCCESS,
	payload: auditlog,
});

export const getAuditLogError = (message) => ({
	type: GET_AUDIT_LOG_ERROR,
	payload: { message },
})


export const getFiltersDataAuditLogs = (filterName) => {
	return{
		type: GET_FILTERATION_DATA_LOG,
		payload:  filterName ,
	}
	
};
export const getFiltersDataAuditLogsSuccess = (auditLogs) => {

	
	return {
		type: GET_FILTERATION_DATA_LOG_SUCCESS,
	payload:  auditLogs ,
    };
};

export const getFiltersDataAuditLogsError = (message) => {
	

	return {
		type: GET_FILTERATION_DATA_LOG_ERROR,
		payload:  {message} ,
    };
};








// export const clearTeamSuccess = () => ({
// 	type: CLEAR_TEAM_SUCCESS,
// });

