export const defualtWidgetTheme = "BasicTheme";
export const widgetThemeStorageKey = "__oval_theme_selected_color";
export const appRoot = "/app";
export const loginPage = "/user/login";
// export const servicePath = "https://mdec-oval-3d5084e7f29a.herokuapp.com/api";
// export const appUrl = "https://mdec-oval-3d5084e7f29a.herokuapp.com";
// export const baseAppUrl = "https://mdec.ovalapp.bio";
export const servicePath =
  "https://oval-teams-api-dev-82de4f6c5cf6.herokuapp.com/api";
export const appUrl = "https://oval-teams-api-dev-82de4f6c5cf6.herokuapp.com";
export const baseAppUrl = "https://teams-dev.ovalapp.bio";
// export const servicePath = "http://localhost:8800/api";
// export const appUrl = "https://localhost:8800";
// export const baseAppUrl = "http://localhost:3000";
export const STRIPE_PUBLISH =
  "pk_live_51LYMjuAjN1ano4Ii8GP4wMDd8gCmazqkxLaZiGOjOpPseD8hICaWWWPC8VwN9ICZRqrP8sd7I7pXNjaDdrWKRBfM0091OAwNrq";

export const currentUser = {};
