import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken, objectToQueryString } from "helpers/Utils";
import {  GET_ANALYTICS_GRAPHICAL, SAVE_ANALYTICS, GET_ANALYTICS_LISTING,DOWNLOAD_ANALYTICS } from "../actions";

import { 
    getAnalyticGraphicalSuccess,
    getAnalyticGraphicalError,
    saveAnalyticError,
    saveAnalyticSuccess,
    getAnalyticListingSuccess,
    getAnalyticListingError,
    downloadExcelAnalyticsError,
    downloadExcelAnalyticsSuccess,
} from "./actions";
import instance from "redux/instance/instanceApi";
  

export function* watchGetAnalyticGraphical() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_ANALYTICS_GRAPHICAL, getAnalyticGraphical);
}

function* getAnalyticGraphical({ payload }) {
    try {

        const analytic = yield call(getAnalyticGraphicalAsync, payload);

        if (!analytic.message) {
            yield put(getAnalyticGraphicalSuccess(analytic));
        } else {
            yield put(getAnalyticGraphicalError(analytic.message));
        }
    } catch (error) {
        yield put(getAnalyticGraphicalError(error));
    }
}

const getAnalyticGraphicalAsync = async (payload) => {
    try {
        let response = await instance.get('/analytics/graphical-view?' + objectToQueryString(payload));

        return response.data;
    } catch (error) {

    }
};

export function* watchGetAnalyticListing() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_ANALYTICS_LISTING, getAnalyticListing);
}

function* getAnalyticListing({ payload }) {
    try {
        const analytic = yield call(getAnalyticListingAsync , payload);

        if (!analytic.message) {
            yield put(getAnalyticListingSuccess(analytic));
        } else {
            yield put(getAnalyticListingError(analytic.message));
        }
    } catch (error) {
        yield put(getAnalyticListingError(error));
    }
}

const getAnalyticListingAsync = async (payload) => {
    try {
        let response = await instance.get('/analytics/listing-view?' + objectToQueryString(payload));

        return response.data;
    } catch (error) {

    }
};


export function* watchSaveAnalytic() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(SAVE_ANALYTICS, saveAnalytic);
}

function* saveAnalytic({ payload }) {
	const { analytic } = payload;
	try {
		const response = yield call(saveAnalyticAsync, analytic);
		if (!response?.error) {
            yield put(saveAnalyticSuccess(response.message));
        } else {
            yield put(saveAnalyticError(response.message));
        }
	} catch (error) {
	}
}

const saveAnalyticAsync = async (analytic) => {
	try {
		
    let response = await instance.post('/analytics', {...analytic});

		return response.data;
	} catch (error) {
        console.log("error", error)
	}
};


export function* watchDownloadAnalytics() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(DOWNLOAD_ANALYTICS, downloadAnalytics);
}

function* downloadAnalytics({ payload }) {
	// const { analytic } = payload;
	try {
		const response = yield call(downloadAnalyticsAsync, payload);
		if (!response?.error) {
            // yield put(downloadExcelAnalyticsSuccess(response.message));
        } else {
            // yield put(downloadExcelAnalyticsError(response.message));
        }
	} catch (error) {
        yield put(downloadExcelAnalyticsError(error));
	}
}

const downloadAnalyticsAsync = async (payload) => {
	try {
        let response = await instance.get("/analytics/download-csv-file?" + objectToQueryString(payload));

		return response.data;
	} catch (error) {
        console.log("error", error)
	}
};


export default function* rootSaga() {
    yield all([
        fork(watchGetAnalyticGraphical), 
        fork(watchSaveAnalytic), 
        fork(watchGetAnalyticListing),
        fork(watchDownloadAnalytics),

    ]);
}
