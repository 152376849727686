import {

	GET_DEPARTMENT,
	GET_DEPARTMENT_SUCCESS,
	GET_DEPARTMENT_ERROR,

	GET_DEPARTMENT_BY_COMPANY,
	GET_DEPARTMENT_BY_COMPANY_SUCCESS,
	GET_DEPARTMENT_BY_COMPANY_ERROR,

	GET_ALL_DEPARTMENT,
	GET_ALL_DEPARTMENT_SUCCESS,
	GET_ALL_DEPARTMENT_ERROR,


	SAVE_DEPARTMENT,
	SAVE_DEPARTMENT_SUCCESS,
	SAVE_DEPARTMENT_ERROR,

	UPDATE_DEPARTMENT,
	UPDATE_DEPARTMENT_SUCCESS,
	UPDATE_DEPARTMENT_ERROR,

	DELETE_DEPARTMENT,
	DELETE_DEPARTMENT_SUCCESS,
	DELETE_DEPARTMENT_ERROR,

	GET_FILTERATION_DATA,
	GET_FILTERATION_DATA_SUCCESS,
	GET_FILTERATION_DATA_ERROR,

	RESET_FILTERATION_DATA_SUCCESS,
	RESET_FILTERATION_DATA,
  RESET_FILTERATION_DATA_ERROR,
  CLEAR_DEPARTMENT_SUCCESS

} from "../actions";


export const getAllDepartments = () => ({
	type: GET_ALL_DEPARTMENT,
});

export const getAllDepartmentsSuccess = (departments) => ({
	type: GET_ALL_DEPARTMENT_SUCCESS,
	payload: departments,
});

export const getAllDepartmentsError = (message) => ({
	type: GET_ALL_DEPARTMENT_ERROR,
	payload: { message },
})


export const saveDepartment = (department,companyId) => ({
	type: SAVE_DEPARTMENT,
	payload: { department,companyId },
});

export const saveDepartmentSuccess = (message) => ({
	type: SAVE_DEPARTMENT_SUCCESS,
	payload: {message},
});

export const saveDepartmentError = (message) => ({
	type: SAVE_DEPARTMENT_ERROR,
	payload: { message },
});

export const getDepartment = (id) => ({
	type: GET_DEPARTMENT,
	payload: { id },
});

export const getDepartmentSuccess = (deparment) => ({
	type: GET_DEPARTMENT_SUCCESS,
	payload: deparment,
});

export const getDepartmentError = (message) => ({
	type: GET_DEPARTMENT_ERROR,
	payload: { message },
});

export const getDepartmentByCompany = (id) => ({
	type: GET_DEPARTMENT_BY_COMPANY,
	payload: { id },
});
export const getDepartmentByCompanySuccess = (departments) => ({
	type: GET_DEPARTMENT_BY_COMPANY_SUCCESS,
	payload: departments,
});

export const getDepartmentByCompanymentError = (message) => ({
	type: GET_DEPARTMENT_BY_COMPANY_ERROR,
	payload: { message },
});


export const updateDepartment = (department,dep_id,companyId) => ({
	type: UPDATE_DEPARTMENT,
	payload: { department,dep_id,companyId },
});

export const updateDepartmentSuccess = (message) => ({
	type: UPDATE_DEPARTMENT_SUCCESS,
	payload: {message},
});

export const updateDepartmentError = (message) => ({
	type: UPDATE_DEPARTMENT_ERROR,
	payload: { message },
});

export const deleteDepartment = (id,companyId) => ({
	type: DELETE_DEPARTMENT,
	payload: { id,companyId },
});

export const deleteDepartmentSuccess = (id) => {
    return {
        type: DELETE_DEPARTMENT_SUCCESS,
        payload: {id},
    };
};
export const deleteDepartmentError = (message) => ({
	type: DELETE_DEPARTMENT_ERROR,
	payload: { message },
});

export const getFilternationData = (filterName) => {
	return{
		type: GET_FILTERATION_DATA,
		payload:  {filterName} ,
	}
	
};
export const getFilternationDataSuccess = (departments) => {

	
	return {
		type: GET_FILTERATION_DATA_SUCCESS,
		payload:  departments,
    };
};

export const getFilternationDataError = (message) => {
	

	return {
		type: GET_FILTERATION_DATA_ERROR,
		payload:  {message} ,
    };
};

export const resetFilters= (departments) => {
	return{
		type: RESET_FILTERATION_DATA,
		payload:  {departments} ,
	}	
	
};


export const filterResetSuccess= (departments) => {
	return{
		type: RESET_FILTERATION_DATA_SUCCESS,
		payload:  departments ,
	}	
	
};

export const clearDepartmentSuccess = () => {
	return {

		type: CLEAR_DEPARTMENT_SUCCESS,
	}
};



