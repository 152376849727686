/* eslint-disable no-param-reassign */
import {
  
  GET_ALL_WIDGET,
  GET_ALL_WIDGET_SUCCESS,
  GET_ALL_WIDGET_ERROR,
  UPDATE_WIDGET,UPDATE_WIDGET_SUCCESS,UPDATE_WIDGET_ERROR,
  CREATE_WIDGET,CREATE_WIDGET_SUCCESS,CREATE_WIDGET_ERROR,
  GET_ALL_WIDGET_BY_COMPANY,
  GET_ALL_WIDGET_BY_COMPANY_SUCCESS,
  GET_ALL_WIDGET_BY_COMPANY_ERROR,
  CLEAR_COMPANY_WIDGETS_SUCCESS

} from "../actions";

export const getAllWidget = () => {
  return {
    type: GET_ALL_WIDGET,
  };
}

export const getAllWidgetSuccess = (widgets) => {
	return {
		type: GET_ALL_WIDGET_SUCCESS,
		payload:  widgets,
    };
}


export const getAllWidgetError = (message) => {
  return {
    type:   GET_ALL_WIDGET_ERROR,
    payload:{message}
  };
}
export const getAllWidgetByCompany = (id) => {
  return {
    type: GET_ALL_WIDGET_BY_COMPANY,
    payload:id
  };
}

export const getAllWidgetByCompanySuccess = (companyWidgets) => {
  return {
    type: GET_ALL_WIDGET_BY_COMPANY_SUCCESS,
    payload:companyWidgets

  };
}

export const getAllWidgetByCompanyError = (message) => {
  return {
    type: GET_ALL_WIDGET_BY_COMPANY_ERROR,
    payload:{message}

  };
}

export const updateWidgetSettings = (data,settingId) => {
  return {
    type:  UPDATE_WIDGET ,
    payload:{data,settingId}
  };
}

export const updateWidgetSettingsSuccess = (data) => {
  return {
    type:  UPDATE_WIDGET_SUCCESS ,
    payload:data
  };
}

export const updateWidgetSettingsError = (message) => {
  return {
    type:   UPDATE_WIDGET_ERROR,
    payload:{message}
  };
}

export const createWidgetSetting = (data) => {
  return {
    type:   CREATE_WIDGET,
    payload:data
  };
}

export const createWidgetSettingSuccess = (data) => {
  return {
    type:   CREATE_WIDGET_SUCCESS,
    payload:data
  };
}

export const createWidgetSettingError = (message) => {
  return {
    type:   CREATE_WIDGET_ERROR,
    payload:{message}
  };
}


export const clearCompanyWidgetsSuccess = () => {
	return {

		type: CLEAR_COMPANY_WIDGETS_SUCCESS,
	}
};