import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from 'redux-saga';
// import reducers from './reducers';
import rootreducer from './reducers';

import sagas from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' 
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
const persistConfig = {
	key: 'root',
	storage,
  }
const configureStore = () => {
	const store = createStore(
		rootreducer,
		composeWithDevTools(applyMiddleware(...middlewares)),
	);

	sagaMiddleware.run(sagas);
	return store;

};
const store = configureStore();
 


export default store;
