import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import { TEMPLATES_GET, ICONS_GET } from "../actions";

import { getTemplatesSuccess, getTemplatesError, getIconsSuccess, getIconsError } from "./actions";
import instance from "redux/instance/instanceApi";

export function* watchGetTemplates() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(TEMPLATES_GET, getTemplates);
}

function* getTemplates({ payload }) {
    const { history } = payload;
    try {
        const templates = yield call(getTemplatesdAsync);

        if (!templates.message) {
            yield put(getTemplatesSuccess(templates));
        } else {
            yield put(getTemplatesError(templates.message));
        }
    } catch (error) {
        yield put(getTemplatesError(error));
    }
}


const getTemplatesdAsync = async () => {
    try {
       
        let response = await instance.get('/template/active');

        return response.data;
    } catch (error) {

    }
};

// Get Icons

export function* watchGetIcons() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(ICONS_GET, getIcons);
}

function* getIcons({ payload }) {
    const { history } = payload;
    try {
        const icons = yield call(getgetIconsdAsync);

        if (icons) {
            yield put(getIconsSuccess(icons));
        } else {
            yield put(getIconsError(icons));
        }
    } catch (error) {
        console.log(error);
        yield put(getIconsError(error));
    }
}


const getgetIconsdAsync = async () => {
    try {
        
        let response = await instance.get('/icons/active');

        return response.data;
    } catch (error) {

    }
};

export default function* rootSaga() {
    yield all([fork(watchGetTemplates), fork(watchGetIcons)]);
}
