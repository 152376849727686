import Logout from "assets/img/rightSidebarIcons/Logout";
import Swal from "sweetalert2";

const SimpleAlert = (icon, message) => {
  Swal.fire({
    icon: icon,
    text: message,
    showConfirmButton: false,
    timer: 1500,
    customClass: {
      container: "simple-alert-container custom_class_container_swal_alert",
      icon: "icon-simple-alert-container",
    },
  });
};

const SimpleAlertWithTitle = (icon, data) => {

  return new Promise((resolve) => {
    Swal.fire({
      icon: icon,
      html: `<b>${data?.title}</b><br>
      ${data?.message}`,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        container: "simple-alert-container custom_class_container_swal_alert",
        icon: "icon-simple-alert-container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else if (result.isDenied) {
        resolve(false);
      }
      resolve(false);
    });
  });
};

const DiscardAlert = () => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to discard changes!",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Discard changes",
      confirmButtonColor: "#00a1ed",

      customClass: {
        container: "test-alet-conteinereeee custom_class_container_swal_alert",
        icon: "icon_custom_test_containerrrr",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else if (result.isDenied) {
        resolve(false);
      }
      resolve(false);
    });
  });
};

const DeleteAlert = () => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "question",
      showCancelButton: true,

      confirmButtonColor: "#00a1ed",
      confirmButtonText: "Yes, delete it!",
      closeOnClickOutside: false,
      customClass: {
        container: "custom_class_container_swal_alert",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

const LogOutAlert = () => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Log out",
      icon: "question",

      closeOnClickOutside: false,
      confirmButtonColor: "#00a1ed",
      showCancelButton: true,
      customClass: {
        container: "custom_class_container_swal_alert",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

const UpdateAlert = () => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Are you sure?",
      text: " You want to Update this!",
      icon: "question",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      customClass: "Custom_Cancel_swal",
      confirmButtonColor: "#00a1ed",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

const AddAlert = () => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Are you sure?",
      text: " You want to Add this!",
      icon: "question",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      customClass: "Custom_Cancel_swal",
      confirmButtonColor: "#00a1ed",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

export {
  DeleteAlert,
  UpdateAlert,
  DiscardAlert,
  LogOutAlert,
  AddAlert,
  SimpleAlertWithTitle,
};

export default SimpleAlert;
