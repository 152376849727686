import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
  GET_EXCHANGECONTACT,
  GET_EXCHANGECONTACT_BY_COMPANY,
  GET_EXCHANGECONTACT_FILTERATION_DATA,
  UPDATE_EXCHANGECONTACT,
  SAVE_EXCHANGECONTACT,
  DELETE_EXCHANGECONTACT,
  GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME,
} from "../actions";

import {
  getExchangeContactSuccess,
  getExchangeContactError,
  getExchangeContactByCompanySuccess,
  getExchangeContactByCompanymentError,
  getExchangeContactByEmployeeNameSuccess,
  getExchangeContactByEmployeeNameError,
  saveExchangeContactError,
  saveExchangeContactSuccess,
  updateExchangeContactError,
  updateExchangeContactSuccess,
  deleteExchangeContactSuccess,
  deleteExchangeContactError,
  getFilterationDataSuccess,
  getFilterationDataError,
} from "./actions";
import instance from "redux/instance/instanceApi";
import SimpleAlert from "components/common/Swal Alert";
import { objectToQueryString } from "helpers/Utils";

export function* watchGetExchangeContact() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_EXCHANGECONTACT, getExchangeContact);
}

function* getExchangeContact({ payload }) {
  const { id } = payload;
  try {
    const exchangeContact = yield call(getExchangeContactAsync, id);

    if (!exchangeContact?.message) {
      yield put(getExchangeContactSuccess(exchangeContact));
    } else {
      yield put(getExchangeContactError(exchangeContact?.message));
    }
  } catch (error) {
    yield put(getExchangeContactError(error));
  }
}

const getExchangeContactAsync = async (id) => {
  try {
   
    let response = await instance.get( "/econtacts/" + id);

    return response.data;
  } catch (error) {}
};

export function* watchGetExchangeContactByCompany() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_EXCHANGECONTACT_BY_COMPANY, getExchangeContactByCompany);
}

function* getExchangeContactByCompany({ payload }) {
  const { id } = payload;

  try {
    const exchangeContacts = yield call(getExchangeContactByCompanyAsync, id);

    if (!exchangeContacts?.message) {
      yield put(
        getExchangeContactByCompanySuccess(exchangeContacts?.exchangeContacts)
      );
    } else {
      yield put(
        getExchangeContactByCompanymentError(exchangeContacts?.message)
      );
    }
  } catch (error) {
    yield put(getExchangeContactByCompanymentError(error));
  }
}

const getExchangeContactByCompanyAsync = async (id) => {
  try {

    if(id){

      let response = await instance.get(`/exchangecontacts?companyId=${id}`);
      return response.data;
    }else {
      let response = await instance.get(`/exchangecontacts`);
      return response.data;
    }
    

    
  } catch (error) {
    console.log("error===========",error,response);
  }
};

export function* watchGetExchangeContactByEmployeeName() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(
    GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME,
    getExchangeContactByEmployeeName
  );
}

function* getExchangeContactByEmployeeName({ payload }) {
  const { id } = payload;

  try {
    const exchangeContact = yield call(
      getExchangeContactByEmployeeNameAsync,
      id
    );

    if (!exchangeContact?.message) {
      yield put(
        getExchangeContactByEmployeeNameSuccess(
          exchangeContact?.exchangeContacts
        )
      );
    } else {
      yield put(
        getExchangeContactByEmployeeNameError(exchangeContact?.message)
      );
    }
  } catch (error) {
    yield put(getExchangeContactByEmployeeNameError(error));
  }
}

const getExchangeContactByEmployeeNameAsync = async (id) => {
  try {
    // const testname = "moiz zaman";
  
    let response = await instance.get(`/exchangecontacts?userId=${id}`);

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.message ? error?.response?.data?.message : error?.response  ? error?.response : error;
    console.log("err==============",err);

    SimpleAlert("error",`${err}`)
  }
};

export function* watchSaveExchangeContact() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SAVE_EXCHANGECONTACT, saveExchangeContact);
}

function* saveExchangeContact({ payload }) {
  try {
    const response = yield call(saveExchangeContactAsync, payload);
    const actionName = "Add Exchange Contact";
    const description = "Exchange Contact successfully Added";
    if (!response?.error) {
      
      yield put(saveExchangeContactSuccess(response.message));
    } else {
      yield put(saveExchangeContactError(response.message));
    }
  } catch (error) {
    yield put(saveExchangeContactError(error));
  }
}

const saveExchangeContactAsync = async (payload) => {
  try {
  
    let response = await instance.post( "/econtacts",
      { ...payload });

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export function* watchUpdateExchangeContact() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_EXCHANGECONTACT, updateExchangeContact);
}

function* updateExchangeContact({ payload }) {
  const { exchangeContact, dep_id } = payload;
  try {
    const response = yield call(
      updateExchangeContactAsync,
      exchangeContact,
      dep_id
    );
    const actionName = "Update Exchange Contact";
    const description = "Exchange Contact Updated successfully";
    if (!response?.error) {
      // yield call(
      //   createAuditLogAsync,
      //   actionName,
      //   description
      // );
      yield put(updateExchangeContactSuccess(response.message));
    } else {
      yield put(updateExchangeContactError(response.message));
    }
  } catch (error) {}
}

const updateExchangeContactAsync = async (exchangeContact, dep_id) => {
  try {
 
    let response = await instance.post("/econtacts/" + dep_id);
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export function* watchDeleteExchangeContact() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_EXCHANGECONTACT, deleteExchangeContact);
}

function* deleteExchangeContact({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteExchangeContactAsync, id);

    const exchangeContact = {
      ...response,
      id: id,
    };

    if (exchangeContact?.success === true) {
      yield put(deleteExchangeContactSuccess(id));
    } else {
      yield put(deleteExchangeContactError(exchangeContact?.message));
    }
  } catch (error) {
    yield put(deleteExchangeContactError(error));
  }
}

const deleteExchangeContactAsync = async (id) => {
  try {
    
    let response = await instance.delete( "/econtacts/" + id);

    return response.data;
  } catch (error) {}
};

export function* watchGetFilterationDataExchangeContacts() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(
    GET_EXCHANGECONTACT_FILTERATION_DATA,
    getfilternationExchangeContactByCompany
  );
}

function* getfilternationExchangeContactByCompany({ payload }) {
  try {
    const exchangeContacts = yield call(
      getFilterationExchangeContactByCompanyAsync,
      payload
    );
    if (!exchangeContacts?.message) {
      yield put(
        getFilterationDataSuccess(exchangeContacts?.exchangeContacts)
      );
    } else {
      yield put(
        getFilterationDataError(exchangeContacts?.message)
      );
    }
  } catch (error) {
    yield put(getFilterationDataError(error));
  }
}

const getFilterationExchangeContactByCompanyAsync = async (payload) => {
  try {
    // const departmentId = payload?.search?.departmentId;
    // const teamId = payload?.search?.teamId;
    // const name = payload?.search?.name;

   
    let response = await instance.get(`/exchangecontacts?` + objectToQueryString(payload) );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export default function* rootSaga() {
  yield all([
    fork(watchGetExchangeContact),
    fork(watchGetExchangeContactByCompany),
    fork(watchSaveExchangeContact),
    fork(watchUpdateExchangeContact),
    fork(watchDeleteExchangeContact),
    fork(watchGetFilterationDataExchangeContacts),
    fork(watchGetExchangeContactByEmployeeName),
  ]);
}
