import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { appRoot } from "constants/defaultValues";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import {
  CREATE_WIDGET,
  GET_ALL_WIDGET,
  GET_ALL_WIDGET_BY_COMPANY,
  UPDATE_WIDGET,
} from "../actions";
import {
  setPreviewSideBarSuccess,
  getAllWidgetSuccess,
  getAllWidgetError,
  createWidgetSettingSuccess,
  createWidgetSettingError,
  getAllWidgetByCompanySuccess,
  getAllWidgetByCompanyError,
} from "./actions";
import { getUserInfo } from "../auth/actions";
import { getCurrentTheme, getCurrentUser } from "helpers/Utils";
import SimpleAlert from "components/common/Swal Alert";
import instance from "redux/instance/instanceApi";

export function* watchGetAllWidget() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_WIDGET, getallWidget);
}
function* getallWidget() {
  try {
    const response = yield call(getAllWidgetAsync);
    if (response?.widgets) {
      yield put(getAllWidgetSuccess(response?.widgets));
    } else {
      yield put(getAllWidgetError(response.data));
      // yield put(setUsernameExistError(false));
    }
  } catch (error) {
    // console.log("error=========getallWidget==========:", error);
  }
}

const getAllWidgetAsync = async () => {
  try {
    let response = await instance.get("/widgets");
    return response.data;
  } catch (error) {
    const err = error?.response?.data;
    // console.log("error======================",error.response)
    SimpleAlert("error", "error.response", err);
  }
};

export function* watchUpdateWidget() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_WIDGET, updateWidget);
}

function* updateWidget(payload) {
  try {
    const response = yield call(updateWidgetAsync, payload);
    if (response?.widgets) {
      // yield put(updateWidgetSettingsSuccess(response.widgets));
    } else {
      // yield put(updateWidgetSettingsError(response.data));
    }
  } catch (error) {
    // console.log("error=========updateWidget==========:", error);
  }
}

const updateWidgetAsync = async ({payload}) => {
  const {data,settingId} = payload;

  try {
    let response = await instance.post(`/companyWidgetsSetting/${settingId}`,{...data});
    return response.data;
  } catch (error) {
    const err = error?.response?.data;
    // console.log("err===============",err)
    SimpleAlert("error", "error.response", err);
  }
};

export function* watchCreateCompanySettings() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CREATE_WIDGET, createWidgetSettings);
}
function* createWidgetSettings({ payload }) {
  try {
    const response = yield call(createWidgetSettingsAsync, payload);
    if (!response?.message) {
      yield put(createWidgetSettingSuccess(response));
    } else { 
      const err = response.message ? response.message : response
      yield put(createWidgetSettingError(err));
      // yield put(setUsernameExistError(false));
    }
  } catch (error) {
    yield put(createWidgetSettingError(error));

    // console.log("error=========getallWidget==========:", error);
  }
}

const createWidgetSettingsAsync = async (payload) => {

  // const {data} = payload;
  try {
    let response = await instance.post("/companyWidgetsSetting", payload);
    return response.data;
  } catch (error) {
    // console.log("error=======createWidgetSettingsAsync===", error.response);

    return error?.response?.data;
  }
};

export function* watchGetAllWidgetByCompany() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_WIDGET_BY_COMPANY, getAllWidgetbyCompany);
}
function* getAllWidgetbyCompany(payload ) {
  try {
    const response = yield call(getAllWidgetByCompanyAsync, payload);
// 
    if (response?.settings) {
      yield put(getAllWidgetByCompanySuccess(response?.settings));
    } else {
      yield put(getAllWidgetByCompanyError(response?.message));
      // yield put(setUsernameExistError(false));
    }
  } catch (error) {
    console.log("error=========getallWidget==========:", error);
  }
}

const getAllWidgetByCompanyAsync = async ({payload}) => {

  try {
    let response = await instance.get(
      `companyWidgetsSettings?companyId=${payload}`
    );

    return response.data;
  } catch (error) {
    console.log("error==========", error.response);
  }
};

export default function* rootSaga() {
  yield all([fork(watchGetAllWidget), fork(watchCreateCompanySettings), fork(watchGetAllWidgetByCompany), fork(watchUpdateWidget)]);
}
