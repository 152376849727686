import {
	GET_COMPANY,
	GET_COMPANY_SUCCESS,
	GET_COMPANY_ERROR,
	
	GET_COMPANY_BY_ID,
	GET_COMPANY_BY_ID_SUCCESS,
	GET_COMPANY_BY_ID_ERROR,

	GET_COMPANY_DETAIL_BY_ID,
	GET_COMPANY_DETAIL_BY_ID_SUCCESS,
	GET_COMPANY_DETAIL_BY_ID_ERROR,
	
	GET_ALL_COMPANIES,
    GET_ALL_COMPANIES_SUCCESS,
    GET_ALL_COMPANIES_ERROR,

	GET_FILTERATION_COMPANY_OWNER_BY_USERID,
    GET_FILTERATION_COMPANY_OWNER_BY_USERID_SUCCESS,
    GET_FILTERATION_COMPANY_OWNER_BY_USERID_ERROR,
	
	SAVE_COMPANY,
	SAVE_COMPANY_SUCCESS,
	SAVE_COMPANY_ERROR,

	SAVE_COMPANY_BY_SA,
	SAVE_COMPANY_BY_SA_SUCCESS,
	SAVE_COMPANY_BY_SA_ERROR,

	UPDATE_COMPANY,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_ERROR,

	SET_COMPANY_SELECTED,

	CLEAR_TEAM_SUCCESS,
	CLEAR_COMPANY_SUCCESS,




} from "../actions";

export const getallCompanies = () => ({
	type: GET_ALL_COMPANIES,
});

export const getallCompaniesSuccess = (company) => ({
	type: GET_ALL_COMPANIES_SUCCESS,
	payload: company,
});

export const getallCompaniesError = (message) => ({
	type: GET_ALL_COMPANIES_ERROR,
	payload: { message },
});


export const getCompanyById = (id, history) => ({
	type: GET_COMPANY_BY_ID,
	payload: { id, history },
});

export const getCompanyByIdSuccess = (company) => ({
	type: GET_COMPANY_BY_ID_SUCCESS,
	payload: company,
});

export const getCompanyByIdError = (message) => ({
	type: GET_COMPANY_BY_ID_ERROR,
	payload: { message },
});


export const getCompanyDetailsById = (id, history) => ({
	type: GET_COMPANY_DETAIL_BY_ID,
	payload: { id, history },
});

export const getCompanyDetailByIdSuccess = (company) => ({
	type: GET_COMPANY_DETAIL_BY_ID_SUCCESS,
	payload: company,
});

export const getCompanyDetailByIdError = (message) => ({
	type: GET_COMPANY_DETAIL_BY_ID_ERROR,
	payload: { message },
});


export const getCompany = (company, history) => ({
	type: GET_COMPANY,
	payload: { company, history },
});

export const getCompanySuccess = (company) => ({
	type: GET_COMPANY_SUCCESS,
	payload: company,
});

export const getCompanyError = (message) => ({
	type: GET_COMPANY_ERROR,
	payload: { message },
});

export const saveCompany = (company) => ({
	type: SAVE_COMPANY,
	payload: { company },
});

export const saveCompanySuccess = (message) => ({
	type: SAVE_COMPANY_SUCCESS,
	payload: {message},
});

export const saveCompanyError = (message) => ({
	type: SAVE_COMPANY_ERROR,
	payload: { message },
});



export const saveCompanyBySA = (company) => ({
	type: SAVE_COMPANY_BY_SA,
	payload: { company },
});

export const saveCompanyBySASuccess = (message) => ({
	type: SAVE_COMPANY_BY_SA_SUCCESS,
	payload: {message},
});

export const saveCompanyBySAError = (message) => ({
	type: SAVE_COMPANY_BY_SA_ERROR,
	payload: { message },
});


export const updateCompany = (company) => ({
	type: UPDATE_COMPANY,
	payload: { company },
});

export const updateCompanySuccess = (message) => ({
	type: UPDATE_COMPANY_SUCCESS,
	payload: {message},
});

export const updateCompanyError = (message) => ({
	type: UPDATE_COMPANY_ERROR,
	payload: { message },
});

export const clearCompanySuccess = () => {
	return {

		type: CLEAR_COMPANY_SUCCESS,
	}
};

export const setSelectedCompanyBySA = (data) => {
	return {

		type: SET_COMPANY_SELECTED,
		payload:data
	}
};


export const getFilterDataCompanyOwnerByUserId = (userId) => {
	return{
		type: GET_FILTERATION_COMPANY_OWNER_BY_USERID,
		payload:  {userId} ,
	}
	
};
export const getFilterDataCompanyOwnerByUserIdSuccess = (companyowner) => {

	
	return {
		type: GET_FILTERATION_COMPANY_OWNER_BY_USERID_SUCCESS,
		payload:  companyowner,
    };
};

export const getFilterDataCompanyOwnerByUserIdError = (message) => {
	

	return {
		type: GET_FILTERATION_COMPANY_OWNER_BY_USERID_ERROR,
		payload:  {message} ,
    };
};


