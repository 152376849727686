import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
    GET_AUDIT_LOG,
    GET_FILTERATION_DATA_LOG,
    GET_AUDIT_LOG_BY_COMPANY
} from "../actions";

import {
    getAuditLogSuccess,
    getAuditLogError,
    getFiltersDataAuditLogsSuccess,
    getFiltersDataAuditLogsError,
    getAuditLogByCompanySuccess,
    getAuditLogByCompanyError
} from "./actions";
import instance from "redux/instance/instanceApi";
import { objectToQueryString } from "helpers/Utils";

export function* watchGetAuditLogByCompany() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_AUDIT_LOG_BY_COMPANY, getAuditLogsByCompany);
}

function* getAuditLogsByCompany({ payload }) {
    try {
        const auditlog = yield call(getAuditLogsByCompanyAsync, payload);
        if (!auditlog?.message) {
            yield put(getAuditLogByCompanySuccess(auditlog?.auditLogs));
        } else {
            yield put(getAuditLogByCompanyError(auditlog?.message));
        }
    } catch (error) {
        // yield put(getAuditLogError(error));
        yield put(getAuditLogByCompanyError(error));

    }
}

const getAuditLogsByCompanyAsync = async (payload) => {
    const { id } = payload;

    try {
        let response = await instance.get(`/audit_logs?companyId=${id}`);
        return response.data;
    } catch (error) {
        console.log("error ==================", error.response)
    }
};
export function* watchGetAdminAuditLog() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_AUDIT_LOG, getAdminAuditLog);
}

function* getAdminAuditLog() {
    // const { history } = payload;
    try {
        const auditlog = yield call(getAdminAuditLogAsync);
        if (!auditlog?.message) {
            yield put(getAuditLogSuccess(auditlog?.auditLogs));
        } else {
            yield put(getAuditLogError(auditlog?.message));
        }
    } catch (error) {
        yield put(getAuditLogError(error));
    }
}

const getAdminAuditLogAsync = async () => {
    try {
        let response = await instance.get('/audit_logs');
        return response.data;
    } catch (error) {

    }
};

export function* watchGetFilterDataAuditLog() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_FILTERATION_DATA_LOG, getfilterDataAuditLog);
}

function* getfilterDataAuditLog({ payload }) {

    try {
        const auditlog = yield call(getFilterDataAuditLogAsync, payload);

        if (!auditlog?.message) {
            yield put(getFiltersDataAuditLogsSuccess(auditlog.auditLogs));
        } else {
            yield put(getFiltersDataAuditLogsError(auditlog?.message));
        }
    } catch (error) {
        yield put(getFiltersDataAuditLogsError(error));
    }
}

const getFilterDataAuditLogAsync = async (payload) => {
    try {
        let response = await instance.get(`/audit_logs?` + objectToQueryString(payload));
        return response.data;
    } catch (error) {

    }
};

export default function* rootSaga() {
    yield all([
        fork(watchGetAdminAuditLog),
        fork(watchGetFilterDataAuditLog),
        fork(watchGetAuditLogByCompany),
    ]);
}
