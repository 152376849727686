import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
    GET_COMPANY,
    SAVE_COMPANY,
    GET_FILTERATION_COMPANY_OWNER_BY_USERID,
    GET_COMPANY_DETAIL_BY_ID,
    UPDATE_COMPANY,
    GET_COMPANY_BY_ID,
    GET_FILTERATION_DATA,
    GET_ALL_COMPANIES,
    SAVE_COMPANY_BY_SA
    


} from "../actions";

import {
    getCompanySuccess,
    getCompanyError,
    saveCompanyError,
    saveCompanySuccess,
    updateCompanyError,
    updateCompanySuccess,
    getCompanyByIdSuccess,
    getCompanyByIdError,
    getallCompaniesSuccess,
    getallCompaniesError,
    saveCompanyBySAError,
    saveCompanyBySASuccess,
    getCompanyDetailByIdSuccess,
    getCompanyDetailByIdError,
} from "./actions";
import { getIdfromToken } from "helpers/Utils";
import SimpleAlert from "components/common/Swal Alert";
import instance from "redux/instance/instanceApi";

export function* watchGetCompany() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_COMPANY, getCompany);
}



function* getCompany({ payload }) {
    const { history } = payload;
    try {
        const company = yield call(getCompanyAsync);
        if (!company.message) {

            yield put(getCompanySuccess(company));
        } else {
            yield put(getCompanyError(company.message));
            // SimpleAlert("error",`${company.message}`)
        }
    } catch (error) {
        // console.log("eroor-========getCompany=========",error);

        yield put(getCompanyError(error));
    }
}

const getCompanyAsync = async () => {
    try {
        
        let response = await instance.get('/company/owner');
        return response.data;
    } catch (error) {
        // return error.response.data 
        // SimpleAlert("error",`${error?.response?.data?.message}`)
        return error?.response.data;

    }
};

export function* watchGetAllCompanies() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_ALL_COMPANIES, getAllCompaniesData);
}

function* getAllCompaniesData() {
    try {
        const companies = yield call(getAllCompaniesAsync);

        if (!companies.message) {


            yield put(getallCompaniesSuccess(companies));
        } else {

            yield put(getallCompaniesError(companies.message));
        }
    } catch (error) {
        // console.log("eroor-========funciton=========",error);

        yield put(getallCompaniesError(error));
    }
}



const getAllCompaniesAsync = async () => {
    try {
        
        let response = await instance.get('/company');
        return response.data;
    } catch (error) {
        // console.log("eroor-=================",error.response);
   
        return error?.response.data;

    }
};
export function* watchGetCompanyDetailById() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_COMPANY_DETAIL_BY_ID, getCompanyDetailById);
}
function* getCompanyDetailById({ payload }) {
    const { id } = payload;

    try {
        const company = yield call(getCompanyDetailByIdAsync, id);
        if (!company?.message) {
            yield put(getCompanyDetailByIdSuccess(company));
        } else {
            yield put(getCompanyDetailByIdError(company?.message));
        }
    } catch (error) {
        // console.log("eroor-========getCompanyById=========",error);

        yield put(getCompanyDetailByIdError(error));
    }
}

const getCompanyDetailByIdAsync = async (id) => {
    try {
          let response = await instance.get('/company/details/' + id )
        return response.data;
    } catch (error) {
        console.log("error======",error)

    }
};



export function* watchGetCompanyById() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_COMPANY_BY_ID, getCompanyById);
}

function* getCompanyById({ payload }) {
    const { id } = payload;

    try {
        const company = yield call(getCompanyByIdAsync, id);

        if (!company?.message) {
            yield put(getCompanyByIdSuccess(company));
        } else {
            yield put(getCompanyByIdError(company?.message));
        }
    } catch (error) {
        // console.log("eroor-========getCompanyById=========",error);

        yield put(getCompanyByIdError(error));
    }
}

const getCompanyByIdAsync = async (id) => {
    try {
        
          let response = await instance.get('/company/' + id )
        return response.data;
    } catch (error) {
        // console.log("eroor-========getCompanyByIdAsync=========",error.response);

    }
};


export function* watchSaveCompany() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(SAVE_COMPANY, saveCompany);
}

function* saveCompany({ payload }) {
    const { company } = payload;
    try {
        const response = yield call(saveCompanyAsync, company);
        if (!response?.error) {
            yield put(saveCompanySuccess(response.message));
        } else {
            yield put(saveCompanyError(response.message));
        }
    } catch (error) {
    }
}

const saveCompanyAsync = async (company) => {
    try {
        
          let response = await instance.post('/company', { ...company })
        
        return response.data;
    } catch (error) {
        // console.log("error", error)
    }
};


export function* watchSaveCompanyBySA() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(SAVE_COMPANY_BY_SA, saveCompanyBySuperAdmin);
}

function* saveCompanyBySuperAdmin({ payload }) {
    const { company } = payload;
    try {
        const response = yield call(saveCompanyBySuperAdminAsync, company);
        if (!response?.error) {

            yield put(saveCompanyBySASuccess(response?.message));
        } else {

            yield put(saveCompanyBySAError(response?.message));
        }
    } catch (error) {
        // console.log("erro ===saveCompanyBySAError====r", error);

        yield put(saveCompanyBySAError(error));

    }
}

const saveCompanyBySuperAdminAsync = async (company) => {
    try {
        
          let response = await instance.post('/companywithadmin', { ...company });
        
        return response.data;
    } catch (error) {
        console.log("erro =======r", error.response.data);
        const err = error?.response.data?.message ? error?.response.data?.message : error?.response.data ? error?.response.data : ""
        SimpleAlert("error",`${err}`)
    }
};



export function* watchUpdateCompany() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(UPDATE_COMPANY, updateCompany);
}

function* updateCompany({ payload }) {
    const { company } = payload;
    try {
        const response = yield call(updateCompanyAsync, company);
        if (!response?.error  ) {

            yield put(updateCompanySuccess(response.message));
        } else {

            yield put(updateCompanyError(response.date));
        }
    } catch (error) {
    }
}

const updateCompanyAsync = async (company) => {
    try {
      
          let response = await instance.post('/company/'+ company.id, { ...company })
        return response.data;
    } catch (error) {
        // console.log("error", error.response);
        SimpleAlert("error",`${error.response.data}`)
    }
};




export default function* rootSaga() {
    yield all([
        fork(watchGetCompany),
        fork(watchSaveCompany),
        fork(watchUpdateCompany),
        fork(watchGetCompanyById),
        fork(watchGetAllCompanies),
        fork(watchSaveCompanyBySA),
        fork(watchGetCompanyDetailById),



    ]);
}
